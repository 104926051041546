<template>
  <div>
    <div class="search-form">
      <div class="search-input">
        <!-- <Select v-model="selectList"  @on-change="changeVal" style="width:200px;margin-right:10px" placeholder="可选择对应题目查出对比结果">
          <Option v-for="(item,index) in options" :key="index" :value="item.key">{{item.value}}</Option>
        </Select>
        <Tooltip placement="right-start" style="width:200px;margin-right:10px">
            <div slot="content" style="width: 100px;white-space: normal">
              由于社区太多，请输入关键字进行刷选
            </div>
            <Select
                v-model="search.orgCode"
                filterable
                placeholder="点击列表/输入搜索"
                :remote-method="loadingCommunityFn"
                :loading="loadingCommunity"
            >
              <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
            </Select>
        </Tooltip> -->
        <!-- <div style="width:200px;height:30px;display:flex;border:1px solid #ccc;margin-right:10px;line-height:30px">
          <div style="item" v-for="(item,index) in seletOptions" :key="index">{{item.label}}</div>
        </div> -->
        <!-- <Button type="info" style="margin-right:10px">与网上家园对比</Button>
        <Button type="info" style="margin-right:10px">与底册对比</Button> -->
        <!-- <Input v-model="search.addr" placeholder="请输入地址" style="width:150px;margin-right:10px" />
        <Input v-model="search.mobile" placeholder="请输入联系电话" style="width:150px;margin-right:10px" /> -->
    
        
        <!-- <Button type="primary" style="margin-right:10px" @click="searchBtn">查询</Button>
        <Button type="info" @click="reset" style="margin-right:10px">重置</Button> -->
        <Button
          type="primary"
          @click="exportTableData"
          style="margin-right: 10px"
          v-if="showExcel"
        >
          <Icon type="ios-download-outline"></Icon>导出
        </Button>
      </div>
    </div>
    <div style="display:flex;height:35px;line-height:35px;margin-bottom:10px">
       <Select v-model="selectList"  @on-change="changeVal" style="width:200px;margin-right:10px" placeholder="可选择对应题目查出对比结果">
          <Option v-for="(item,index) in options" :key="index" :value="item.key">{{item.value}}</Option>
        </Select>
        <Tooltip placement="right-start" style="width:200px;margin-right:10px">
            <div slot="content" style="width: 100px;white-space: normal">
              由于社区太多，请输入关键字进行刷选
            </div>
            <Select
                v-model="search.orgCode"
                filterable
                placeholder="点击列表/输入搜索"
                :remote-method="loadingCommunityFn"
                :loading="loadingCommunity"
            >
              <!-- 防止报错，使用了index -->
              <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
            </Select>
        </Tooltip>
        <Button :type="changeType == 1?'info':'dashed'" style="margin-right:10px" @click="changeHome">与网上家园对比</Button>
        <Button :type="changeType == 2?'info':'dashed'" style="margin-right:10px" @click="changeDi">与底册对比</Button>
    </div>
    <div v-if="showExcel" style="display:flex;font-size:16px;font-weight:bold;height:35px;line-height:35px">
              <div style="margin-right:20px" v-for="(item,index) in titleData" :key="index">{{item.title}}：<span style="color:red">{{item.value}}</span></div>
     </div>
    <div v-if="showExcel" style="display:flex;height:35px;line-height:35px;margin-bottom:10px">
      <Input v-model="search.addr" placeholder="请输入地址" style="width:150px;margin-right:10px" />
      <Input v-model="search.mobile" placeholder="请输入联系电话" style="width:150px;margin-right:10px" />
      <Button type="primary" style="margin-right:10px" @click="searchBtn">查询</Button>
      <Button type="info" @click="reset" style="margin-right:10px">重置</Button>
    </div>
    
    <LiefengTable
      :talbeColumns="isColumn==false?talbeColumns:diceColumns"
      :tableData="tableData"
      :loading="loading"
      :fixTable="true"
      :curPage="page"
      :total="total"
      :pagesizeOpts="[20, 30, 50, 100]"
      :page-size="pageSize"
      @hadlePageSize="hadlePageSize"
    ></LiefengTable>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
  props: {
    infoId: String,
    businessType:String,
    functionType:String,
    isShow: false
  },
  data() {
    return {
      // 表格的数据内容
      talbeColumns: [
        {
          title: "区",
          key: "zone",
          minWidth: 150,
          align: "center"
        },
        {
          title: "街道",
          key: "street",
          minWidth: 150,
          align: "center"
        },
        {
          title: "社区",
          key: "orgName",
          minWidth: 150,
          align: "center"
        },
        {
          title: "现住地址信息",
          minWidth: 150,
          key: "addr",
          align: "center"
        },
        {
          title: "姓名",
          key: "userName",
          minWidth: 150,
          align: "center"
        },
        {
          title: "性别",
          key: "sex",
          width: 80,
          align: "center",
          render:(h,params) =>{
              return h('div',{},params.row.sex == 0 ? '未知' :params.row.sex == 1 ?'男':params.row.sex == 2?'女':'未知')
          }
        },
        {
          title: "联系电话",
          minWidth: 120,
          key: "mobile",
          align: "center"
        },
        {
          title: "户内人数",
          minWidth: 120,
          key: "memberNum",
          align: "center"
        },
        {
          title: "填写人数",
          minWidth: 120,
          key: "fillNum",
          align: "center"
        },
        {
          title: "未匹配人数",
          minWidth: 120,
          key: "notMatchNum",
          align: "center"
        },
        // {
        //   title: "是否完成",
        //   minWidth: 50,
        //   align: "center",
        //   render:(h,params) =>{
        //       return h('div',{},params.row.complete == true ? '是' :'否')
        //   }
        // },
        // {
        //     title:'与网上家园匹配状态',
        //     minWidth:100,
        //     align:'center',
        //     render:(h,params) =>{
        //         return h('div',{},params.row.matchHomeOnline == 1?'未匹配':params.row.matchHomeOnline == 2?'已匹配通过':params.row.matchHomeOnline == 3?'匹配未通过':'')
        //     }
        // },
        // {
        //     title:'与底册数据匹配状态',
        //     minWidth:100,
        //     align:'center',
        //     render:(h,params) =>{
        //         return h('div',{},params.row.matchOriginal == 1?'未匹配':params.row.matchOriginal == 2?'已匹配通过':params.row.matchOriginal == 3?'匹配未通过':'')
        //     }
        // },
      ],
      diceColumns: [
        {
          title: "区",
          key: "zone",
          minWidth: 150,
          align: "center"
        },
        {
          title: "街道",
          key: "street",
          minWidth: 150,
          align: "center"
        },
        {
          title: "社区",
          key: "orgName",
          minWidth: 150,
          align: "center"
        },
        {
          title: "现住地址信息",
          minWidth: 120,
          key: "addr",
          align: "center"
        },
        {
          title: "姓名",
          key: "userName",
          minWidth: 150,
          align: "center"
        },
        {
          title: "性别",
          key: "sex",
          width: 80,
          align: "center",
          render:(h,params) =>{
              return h('div',{},params.row.sex == 0 ? '未知' :params.row.sex == 1 ?'男':params.row.sex == 2?'女':'未知')
          }
        },
        {
          title: "联系电话",
          minWidth: 120,
          key: "mobile",
          align: "center"
        },
        // {
        //   title: "是否完成",
        //   minWidth: 50,
        //   align: "center",
        //   render:(h,params) =>{
        //       return h('div',{},params.row.complete == true ? '是' :'否')
        //   }
        // },
        {
            title:'与底册数据匹配状态',
            minWidth:100,
            align:'center',
            render:(h,params) =>{
                return h('div',{},params.row.matchOriginal == 1?'未匹配':params.row.matchOriginal == 2?'已匹配通过':params.row.matchOriginal == 3?'匹配未通过':'')
            }
        },
      ],
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 20,

      //搜索框数据信息
      search:{
          addr:'',
          complete:'',
          matchType:'1',
          mobile:'',
          orgCode:''
      },
      isColumn:false,
      // 发布范围列表
      publishList: [],
      orgCode:[],
      // 选择题目的数组
      options:[],
      selectList:[],
      titleData:[],
      showExcel:false,
      nodeList: [],
      loadingCommunity: false,
      allCommunityList: [],

      seletOptions:[
        {label:'与网上家园比对'},
        {label:'与底册对比'}
      ],
      changeType:0
    };
  },
  methods: {
    // 方法部分
    // 点击网上家园按钮
    changeHome(){
      if(this.selectList.length == 0){
        this.$Message.warning({
          content:'请先选择对应题目',
          background:true
        })
        return
      }
      if(this.search.orgCode == '' || this.search.orgCode == undefined){
        this.$Message.warning({
          content:'请先选择对比地区',
          background:true
        })
        return
      }
      this.changeType = 1
      this.showExcel = true
      this.search.matchType = 1
      this.getList()
    },
    // 点击底册按钮
    changeDi(){
      if(this.selectList.length == 0){
        this.$Message.warning({
          content:'请先选择对应题目',
          background:true
        })
        return
      }
      if(this.search.orgCode == '' || this.search.orgCode == undefined){
        this.$Message.warning({
          content:'请先选择对比地区',
          background:true
        })
        return
      }
      this.changeType = 2
      this.showExcel = true
      this.search.matchType = 2
      this.getList()
    },
    // 选择题目下拉框事件
    changeVal(val){
      this.selectList = []
      this.selectList.push(val)
      // this.selectList = val   
    },
    // 导出
    exportTableData() {
      if(this.selectList.length == 0){
        this.$Message.warning({
          content:'请先选择对应题目',
          background:true
        })
        return
      }
      if(this.search.orgCode == '' || this.search.orgCode == undefined){
        this.$Message.warning({
          content:'请先选择对比地区',
          background:true
        })
        return
      }
      this.$Modal.confirm({
        title: "温馨提示",
        content: "确认导出全部信息",
        onOk: () => {
          this.$post('/old/api/pc/matchUser/exportMatchUserList', {
               addr:this.search.addr,
               busiCode:this.infoId,     
               complete:'',
               matchType:this.search.matchType,
               mobile:this.search.mobile,
               oemCode:parent.vue.oemInfo.oemCode,
               orgCode:this.search.orgCode,
               businessIdList:[],
               searchFieldList:this.selectList
          },{ "Content-Type": "application/json" }).then(res => {
            if (res.code == 200) {
              if(res.data){
                   if(res.data.slice(0,5) == 'http'){
                     res.data = res.data.replace('http','https')
                   }  
                }
              let link = document.createElement("a");
              link.download = '采集信息数据导出.xlsx';
              link.href = res.data;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.$Message.destroy();
            } else {
              this.$Message.warning({
                content: res.desc,
                background: true
              });
            }
          })

        }
      });
    },
    // 重置按钮
    reset(){
      this.search.addr= ''
      this.search.complete= ''
      this.search.mobile= ''
        // this.search ={
        //   addr:'',
        //   complete:'',
        //   matchType:'1',
        //   mobile:'',
        //   orgCode:''
        // }
        // this.selectList = []
        this.page = 1
        this.pageSize = 20
        // this.showExcel = false
        this.tableData = []
        this.getList()
    },
    // 点击查询按钮方法
    searchBtn() {
      if(this.selectList.length == 0){
        this.$Message.warning({
          content:'请先选择对应题目',
          background:true
        })
        return
      }
      if(this.search.orgCode == '' || this.search.orgCode == undefined){
        this.$Message.warning({
          content:'请先选择对比地区',
          background:true
        })
        return
      }
      console.log(this.search);
      this.showExcel = true
      this.getList()
    },
    hadlePageSize(val){
        this.page = val.page
        this.pageSize = val.pageSize
        this.getList()
    },
    // 接口部分
    getList(){
        this.loading = true
        this.$post('/old/api/pc/matchUser/selectByPage',{
            addr:this.search.addr,
            busiCode:this.infoId,     
            complete:'',
            matchType:this.search.matchType,
            mobile:this.search.mobile,
            oemCode:parent.vue.oemInfo.oemCode,
            orgCode:this.search.orgCode,
            businessIdList:[],
            page:this.page,
            pageSize:this.pageSize,
            searchFieldList:this.selectList
        },{"Content-Type": "application/json"}).then(res=>{
            if(res.code == 200){
                this.loading = false
                this.tableData = res.dataList
                this.total = res.maxCount
                this.getData()
                if(this.search.matchType == 1){
                    this.isColumn = false
                }else if(this.search.matchType == 2){
                    this.isColumn = true
                }
            }else{
                this.loading = false
                this.$Message.error({
                    content:res.desc,
                    background:true
                })
                return
            }
        })
    },
   
    // 获取题目信息
    getHeader(){
      this.$get('/old/api/pc/information/v2/selectDynamicFormHeader',{
      businessType: this.businessType,
      functionType: this.functionType,
      operatorCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
      oemCode: parent.vue.oemInfo.oemCode,
      terminal: "2",
      informationId:this.infoId
      }).then(res=>{
        this.options = []
        this.selectList = []
        if(res.dataList && res.dataList.length != 0){
          
          res.dataList.map(item=>{
            this.options.push({
              key:item.tbField,
              value:item.pageFieldName
            })
          })
        }
      })
    },
    // 获取各条数据
    getData(){
     this.$post('/old/api/pc/matchUser/matchSummary', {
               addr:this.search.addr,
               busiCode:this.infoId,     
               complete:'',
               matchType:this.search.matchType,
               mobile:this.search.mobile,
               oemCode:parent.vue.oemInfo.oemCode,
               orgCode:this.search.orgCode,
               businessIdList:[],
               searchFieldList:this.selectList
     },{ "Content-Type": "application/json"}).then(res => {
            if(res.data){
              this.titleData = []
              for(let  i in res.data){
                if(res.data.hasOwnProperty(i)){
                  let o = {
                    title:'',
                    value:''
                  }
                  o.title = i
                  o.value = res.data[i]
                  this.titleData.push(o)
                }
                
              }
              console.log(this.titleData);
              
            }
     })
    },
    loadingCommunityFn(query) {
      if (query !== '' && !Number(query)) {
        this.loadingCommunity = true;
        this.nodeList = this.allCommunityList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
        if (this.nodeList.length > 50) this.nodeList.length = 50;
        this.loadingCommunity = false;
      } else if (Number(query)) {
        return;
      } else {
        this.nodeList = [];
      }
    },
    selectNode() {// 第一次created创建后触发改函数，传了this.userSessionId
        this.$get("/old/api/pc/dynamicform/selectDynamicHaveOrg", {
          busiId:this.infoId
         })
        // this.$get("/gx/pc/staff/selectDatsScopeAndFilterLevel", {
        //   custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        //   orgCode: parent.vue.loginInfo.userinfo.orgCode,
        //   filterLevel: 5,
        //   oemCode: parent.vue.oemInfo.oemCode
        //  })
            .then((res) => {
              if (res.code === "200" && res.dataList && res.dataList.length > 0) {
                this.nodeList = res.dataList.map((item) => {
                  return {
                    value: item.orgCode, //社区编码
                    label: item.orgName, //社区名字
                    projectCode: item.projectCode, //小区编码
                    orgId: item.orgId,
                    orgLevel: item.orgLevel,
                    orgPath: item.orgPath,
                    orgType: item.orgType,
                    parentId: item.parentId,
                    oemCode: parent.vue.oemInfo.oemCode
                  };
                });
                this.allCommunityList = JSON.parse(JSON.stringify(this.nodeList));
                if (this.nodeList.length > 50) {
                  this.nodeList.length = 50;
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
      
      }
  },
    // created() {
    //     this.getList()
    // },
  components: {
    LiefengTable
  },
  watch: {
    isShow: {
      handler(val, newVal) {
        if (val) {
            console.log('如果进来了');
            
            this.page = 1
            this.pageSize = 20
            this.tableData =[]
            this.total = 0
            this.search ={
                addr:'',
                complete:'',
                matchType:'1',
                mobile:'',
                orgCode:''
           }
            this.orgCode = []
            this.selectList = []
            this.showExcel = false
            this.changeType = 0
            this.getHeader()
            this.selectNode()
            // this.getList()
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.search-form {
  display: flex;
  position: fixed;
  top: 8px;
  right: 10px;
  z-index: 999;
  .search-input {
    margin-left: auto;
    display: flex;
    margin-bottom: 10px;
  }
}
/deep/.ivu-table-wrapper{
    height: 600px !important;
}
/deep/.ivu-select-multiple .ivu-select-selection div{overflow: hidden;white-space: nowrap;}
.item{
  width:160px;
  height:30px;
  line-height:30px;
  text-align: center;
  
}
</style>